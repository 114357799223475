export class Unite {

  private _coduni: string;
  private _libuni: string;
  private _unilis: string;
  private _coelis: string;
  private _datcre: string;
  private _datmod: string;
  private _codusr: string;
  private _codede: string;

  constructor(obj: any) {
    Object.assign(this, obj);
  }

  get coduni(): string {
    return this._coduni;
  }

  set coduni(value: string) {
    this._coduni = value;
  }

  get libuni(): string {
    return this._libuni;
  }

  set libuni(value: string) {
    this._libuni = value;
  }

  get unilis(): string {
    return this._unilis;
  }

  set unilis(value: string) {
    this._unilis = value;
  }

  get coelis(): string {
    return this._coelis;
  }

  set coelis(value: string) {
    this._coelis = value;
  }

  get datcre(): string {
    return this._datcre;
  }

  set datcre(value: string) {
    this._datcre = value;
  }

  get datmod(): string {
    return this._datmod;
  }

  set datmod(value: string) {
    this._datmod = value;
  }

  get codusr(): string {
    return this._codusr;
  }

  set codusr(value: string) {
    this._codusr = value;
  }

  get codede(): string {
    return this._codede;
  }

  set codede(value: string) {
    this._codede = value;
  }
}
