import { Injectable } from '@angular/core';
import {environment} from '@env/environment';
import {BaseAbstractService} from '@app/core/services/base-abstract.service';
import {Indmed} from '@app/models/indmed.model';
import {Observable, Subject} from 'rxjs';
import {Alerte} from '@app/models/alerte.model';
import Utils from '@app/shared/services/utils';

@Injectable({
  providedIn: 'root'
})
export class AlertesService extends BaseAbstractService{

  _selectedAlerteAuto: Indmed = null;
  private selectedAlerteAutoSource = new Subject<Indmed>();
  selectedAlerteAutoHandler: Observable<Indmed> = <Observable<Indmed>>this.selectedAlerteAutoSource.asObservable();

  set selectedAlerteAuto(alerte: Indmed) {
    this._selectedAlerteAuto = alerte;
    this.selectedAlerteAutoSource.next(alerte);
  }
  get selectedAlerteAuto(): Indmed {
    return this._selectedAlerteAuto;
  }

  _selectedAlerteManu: Alerte = null;
  private selectedAlerteManuSource = new Subject<Alerte>();
  selectedAlerteManuHandler: Observable<Alerte> = <Observable<Alerte>>this.selectedAlerteManuSource.asObservable();

  set selectedAlerteManu(alerte: Alerte) {
    this._selectedAlerteManu = alerte;
    this.selectedAlerteManuSource.next(alerte);
  }
  get selectedAlerteManu(): Alerte {
    return this._selectedAlerteManu;
  }

  actionAlert: String = null;

  get url(): string {
    return this.getApiUrl() + '/alertes';
  }

  async getManualAlert(date: Date): Promise<Alerte[]> {
    let alertFilter = {
      alertType:"M",
      alertDate:date
    };
    let alertes = (await this.post<any>(`${this.url}`, alertFilter)).map(json => new Alerte(json));
    return alertes;
  }

  async getAutomaticAlert(date: Date): Promise<Indmed[]> {
    let alertFilter = {
      alertType:"A",
      alertDate:date
    };
    let alertes = (await this.post<any>(`${this.url}/indmed`, alertFilter)).map(json => new Indmed(json));
    return alertes;
  }

  async saveAlerte(alerte: Alerte):Promise<Alerte>{
    let jsonAlerte = await this.post<any>(`${this.url}/save`, alerte);
    return new Alerte(jsonAlerte);
  }

  async updateAlerte(alerte: Alerte):Promise<Alerte>{
    let jsonAlerte = await this.post<any>(`${this.url}/update`, alerte);
    return new Alerte(jsonAlerte);
  }


  async download(alerte: Alerte):Promise<any>{
    let promise: Promise<any> = new Promise(async (resolve, reject) => {
      try{
        this.layoutService.loading = true;
        let response:Response =  await this.postBlob(`${this.url}/download`, alerte.codale);
        let downloadUrl= URL.createObjectURL(response.body);

        let filename = response.headers.get('filename') ? response.headers.get('filename') : alerte.filale;

        Utils.downloadBlob(downloadUrl, filename, response);

      }catch(error){
        this.notificationService.addSingleError('Impossible de télécharger les données actuellement');
        reject(error);
      }
      finally {
        this.layoutService.loading = false;
      }
    });
    return promise;
  }

}
