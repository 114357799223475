import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {HistoriqueData} from '@app/models/historique-data.model';
import {ExcelService} from '@app/core/services/excel.service';
import { TableExport } from 'tableexport';
import {ParametreOption} from '@app/models/parametreOption';
console.info(TableExport);
@Component({
  selector: 'app-historique-data',
  templateUrl: './historique-data.component.html',
  styleUrls: ['./historique-data.component.scss']
})
export class HistoriqueDataComponent implements OnInit {

  @ViewChild('tableExport') tableExport:ElementRef ;

  @Input() code: string;
  @Input() set _datas(datas: HistoriqueData[]){
    this.cleanExport();
    this.datas = datas;
  };

  @Input() station: string;
  @Input() param: ParametreOption;
  @Input() year: string;
  @Input() month: string;

  datas: HistoriqueData[];

  paramUnit: string = null;

  table: TableExport = null;

  cols = [
    { field: 'date', header: 'Date', type: 'date' },
    { field: 'param', header: 'Paramètre', type: 'param' },
    { field: 'min', header: 'Minimum', type: 'data' },
    { field: 'moyenne', header: 'Moyenne', type: 'data' },
    { field: 'median', header: 'Médiane', type: 'data' },
    { field: 'max', header: 'Maximum', type: 'data' },
    { field: 'nbData', header: 'Nombre de données', type: 'text' },
    { field: 'deviation_standard', header: 'Déviation standard', type: 'text' },
    { field: 'coefficient_variation', header: 'Coéfficient de variation', type: 'text' },
    { field: 'p2', header: '2', type: 'text' },
    { field: 'p5', header: '5', type: 'text' },
    { field: 'p10', header: '10', type: 'text' },
    { field: 'p90', header: '90', type: 'text' },
    { field: 'p95', header: '95', type: 'text' },
    { field: 'p98', header: '98', type: 'text' },
    { field: 'pDis15m', header: '% Dis. 15m', type: 'text' },
    { field: 'pDis2h', header: '% Dis. 2h', type: 'text' }
  ];

  isDate (type):boolean {
    return type === 'date';
  }

  isText(type):boolean {
    return type === 'text';
  }

  isData(type):boolean {
    return type === 'data';
  }

  isParam(type):boolean {
    return type === 'param';
  }

  constructor(private excelService : ExcelService) { }

  ngOnInit() {
    if(this.code == "journaliers"){
      this.cols = this.cols.slice(0,7);
    }
    this.paramUnit = this.datas[0].parametre.coduni.coduni;
  }

  exportAsXLSX():void {
    this.cleanExport();

    var filename = this.station+'-'+this.param.code+'-'+this.code;
    if(this.year) filename += '-'+this.year;
    if(this.month) filename += '-'+this.month;

    const options = {
      formats: ['xlsx'],
      position: 'top',
      filename: filename
    };
    this.table = new TableExport(this.tableExport['containerViewChild'].nativeElement.getElementsByTagName("table"), options);

    // @ts-ignore
    this.table.typeConfig.date.assert = function(v) {return false;};

    this.tableExport['containerViewChild'].nativeElement.getElementsByTagName("button")[0].click();
    this.cleanExport();
  }

  cleanExport(){
    if(this.table){
      this.table.remove();
    }
  }

}
