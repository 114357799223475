import {Component, OnInit} from '@angular/core';
import {AlertesService} from '@app/core/services/alertes.service';
import {Router} from '@angular/router';
import {UserService} from '@app/core/services/user.service';
import {Alerte} from '@app/models/alerte.model';
import {User} from '@app/models/user.model';
import {LayoutService} from '@app/core/services/layout.service';
import {NotificationService} from '@app/core/services/notification.service';

@Component({
  selector: 'app-alert-action',
  templateUrl: './alert-action.component.html',
  styleUrls: ['./alert-action.component.scss']
})
export class AlertActionComponent implements OnInit {

  form = {
    active:false,
    activeName:null,
    activeDate:null,
    messageIn:null,
    messageOut:null,
    close:false,
    closeName:null,
    closeDate:null,
  };

  alerte: Alerte = null;

  ready: boolean = false;

  constructor(private alertesService : AlertesService,
              private userService: UserService,
              private layoutService: LayoutService,
              private notificationService: NotificationService,
              private router : Router) { }

  ngOnInit() {
    this.init();
  }

  async init(){
    if(this.alertesService.actionAlert == "Manu"){
      this.alerte = this.alertesService.selectedAlerteManu;
    }else if(this.alertesService.actionAlert == "Auto"){
      this.alerte = this.alertesService.selectedAlerteAuto.alerte;
    }

    let users: User[] = await this.userService.getUsers();

    this.form.active = this.alerte.stapec == "O" ? true : false;
    if(this.form.active){
      this.form.activeName = users.find(user => user.logusr == this.alerte.respec).libell;
      this.form.activeDate = this.alerte.datpec;
    }else{
      this.form.activeName = this.userService.user.libell;
      this.form.activeDate = new Date();
    }

    this.form.messageIn = this.alerte.msgpec;

    this.form.messageOut = this.alerte.msgtra;

    this.form.close = this.alerte.staclo == "O" ? true : false;
    if(this.form.close){
      this.form.closeName = users.find(user => user.logusr == this.alerte.resclo).libell;
      this.form.closeDate = this.alerte.datclo;
    }else if(this.form.active){
      this.form.closeName = this.userService.user.libell;
      this.form.closeDate = new Date();
    }

    this.ready = true;
  }


  etapeIn():boolean{
    return false;
  }

  etapeOut():boolean{
    if(this.alerte.stapec == "O")
      return true;
    return false;
  }

  async save(){

    if(!this.alerte.respec){
      this.alerte.stapec = this.form.active ? "O" : "N";
      this.alerte.respec = this.form.activeName ? this.userService.user.logusr : this.alerte.respec ;

      this.alerte.datpec  = this.form.activeDate;

      this.alerte.msgpec = this.form.messageIn;

    }else if(!this.alerte.resclo){
      this.alerte.msgtra = this.form.messageOut;
      this.alerte.staclo = this.form.close ? "O" : "N";
      this.alerte.resclo  = this.form.closeName ? this.userService.user.logusr : this.alerte.resclo;
      this.alerte.datclo  = this.form.closeDate;
    }

    this.layoutService.loading = true;
    try{
      let alert = await this.alertesService.updateAlerte(this.alerte);
      if(alert != null){
        this.notificationService.addSingleSuccess("Alerte Manuelle", "L'alerte a été crée");
      }else{
        this.notificationService.addSingleError("Erreur lors de la création");
      }
    }catch (e){
      this.notificationService.addSingleError("Erreur lors de la création");
    }
    this.layoutService.loading = false;
    this.back();

  }

  displaySave():boolean{
    if(this.alerte.stapec == "O" && this.alerte.staclo == "O"){
      return false;
    }
    return true;
  }

  canSave():boolean {
    if(this.form.active && this.form.activeName && this.form.activeDate && this.form.messageIn){
      return true;
    }
    return false;
  }


  back(){
    this.router.navigate(["/alerts"]);
  }

  checkIn():boolean{
    return this.alerte.stapec == "O" ? true : false;
  }

  checkOut():boolean{
    return this.alerte.staclo == "O" ? true : false;
  }

}
