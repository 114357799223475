import { APP_BASE_HREF } from '@angular/common';
import {Inject, Injectable} from '@angular/core';
import {ConfigService} from './config.service';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';
import {AuthService} from '@app/core/services/auth.service';
import {NotificationService} from '@app/core/services/notification.service';
import {Session} from '@app/app.session';
import {LayoutService} from '@app/core/services/layout.service';
import {environment} from '@env/environment';

@Injectable()
export class BaseAbstractService extends AuthService
{
  constructor(@Inject(APP_BASE_HREF) public baseHref: string,
              public _router: Router,
              private _http: HttpClient,
              private _session: Session,
              private configService: ConfigService,
              public notificationService: NotificationService,
              public layoutService: LayoutService
              ) {
                super(baseHref, _router, _http,_session);
  }

  async get<T>(url:string, options?):Promise<any>{
    return new Promise<any>((resolve,reject)=>this._http.get<T>(url, options).subscribe(val=>resolve(val),reason=>reject(reason)));
  }

  async post<T>(url:string, data ,options?):Promise<any>{
    return new Promise<any>((resolve,reject)=>this._http.post<T>(url, data, options).subscribe(val=>resolve(val),reason=>reject(reason)));
  }

  async put<T>(url:string, data, options?):Promise<any>{
    return new Promise<any>((resolve,reject)=>this._http.put<T>(url, data, options).subscribe(val=>resolve(val),reason=>reject(reason)));
  }

  async delete<T>(url:string, options?):Promise<any>{
    return new Promise<any>((resolve,reject)=>this._http.delete<T>(url, options).subscribe(val=>resolve(val),reason=>reject(reason)));
  }

  async getBlob(url:string, options={}):Promise<Response> {
    options['responseType'] =  'blob';
    options['observe'] = "response";
    return new Promise<any>((resolve,reject)=>this._http.get<Response>(url, options).subscribe(val=>resolve(val),reason=>reject(reason)));
  }
  async postBlob(url:string, data , options={}):Promise<Response> {
    options['responseType'] =  'blob';
    options['observe'] = "response";
    return new Promise<any>((resolve,reject)=>this._http.post<Response>(url, data, options).subscribe(val=>{
      resolve(val);
    },reason=>{
      reject(reason);
    }));
  }

  public getApiUrl(): string {
    if(environment.production){
      return this.baseHref + environment.apiUrl;
    } else {
      return environment.apiUrl;
    }
  }

}
