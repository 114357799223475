import {Layout} from "@app/models/layout.model";
export class AppConfig
{
    // Global
    lang:string = 'fr';
    languages:string[] = ['fr'];

    // Layout
    layout:Layout = new Layout();
}
