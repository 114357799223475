import {Component} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {LayoutComponent} from '@app/features/layout/layout.component';
import {UserService} from '@app/core/services/user.service';
import {Router} from '@angular/router';
import {MyAuthService} from '@app/core/services/my-auth.service';
import {NotificationService} from '@app/core/services/notification.service';

@Component({
    selector: 'app-topbar',
    template: `
        <div class="topbar clearfix">
            <div class="topbar-left">
                <div class="logo"></div>
            </div>

            <div class="topbar-right">
                <a id="menu-button" href="#" (click)="app.onMenuButtonClick($event)">
                    <i></i>
                </a>


              <div class="title">
                <div class="title-main">Aquapol</div>
                <div class="title-sub">Réseau de mesure en continu de la qualité physico-chimique des cours d’eau wallons</div>
              </div>

                <a id="rightpanel-menu-button" href="#" (click)="app.onRightPanelButtonClick($event)" *ngIf="app.layout.rightPanel">
                    <i class="material-icons">more_vert</i>
                </a>

                <a id="topbar-menu-button" href="#" (click)="app.onTopbarMenuButtonClick($event)">
                    <i class="material-icons">menu</i>
                </a>

                <ul class="topbar-items animated fadeInDown" [ngClass]="{'topbar-items-visible': app.topbarMenuActive}">
                    <li #profile class="profile-item" *ngIf="app.profileMode==='top'||app.isHorizontal()" [hidden]="!app.layout.profileView"
                        [ngClass]="{'active-top-menu':app.activeTopbarItem === profile}">

                        <a (click)="login($event, profile)" *ngIf="!userService.user" style="cursor: pointer;">
                          <fa-icon icon="sign-in-alt"></fa-icon>
                          <span class="mobile" style="margin-left: 20px;">Connexion</span>
                        </a>

                        <a (click)="logout($event, profile)"*ngIf="userService.user" style="cursor: pointer;">
                          <i class="material-icons">power_settings_new</i>
                          <span class="mobile" style="margin-left: 20px;">Déconnexion</span>
                        </a>

                    </li>

                </ul>
            </div>
        </div>
    `
})
export class LayoutTopbarComponent
{
    constructor(public app:LayoutComponent,
                public i18n:TranslateService,
                public userService: UserService,
                private authService: MyAuthService,
                private notificationService : NotificationService,
                private route: Router) {}

    get isMultiLang() : boolean {
        return (this.i18n.langs && this.i18n.langs.length > 1);
    }

    onLangChange(selectedLang:string) {
        this.i18n.use(selectedLang);
    }

  login($event, profile){
    $event.stopPropagation();
    this.route.navigate(['/login']);
  }

  logout($event, profile){
    $event.stopPropagation();
    this.authService.logout().subscribe((res) => {
      this.notificationService.addSingleSuccess("Réussite", "Vous êtes à présent déconnecté");
    });
    this.userService.user = null;

    this.route.navigate(['/home']);

  }
}
