import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs/Observable';
import {Injectable} from '@angular/core';
import {EvenementService} from '@app/core/services/evenement.service';

@Injectable()
export class EvenementGuard implements CanActivate {

  constructor(private router: Router,
              private evenementService: EvenementService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
    if (this.evenementService.evenem) {
      return true;
    }

    this.router.navigate(['/home']);
    return false;
  }

}
