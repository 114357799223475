import {DateUtils} from '@app/shared/utils/date.utils';

export class Stapar {

  private _nomsta: string;
  private _codpar: string;
  private _typcha: string;
  private _nument: string;
  private _conver: number;
  private _freana: string;
  private _limdeh: number;
  private _limdeb: number;
  private _cripre: number;
  private _secart: number;
  private _scebas: number;
  private _scehau: number;
  private _typval: string;
  private _nbpini: number;
  private _paspre: number;
  private _ecamax: number;
  private _alarma: string;
  private _alarmm: string;
  private _datalm: Date;
  private _sensal: string;
  private _sebas1: number;
  private _sebas2: number;
  private _sehau1: number;
  private _sehau2: number;
  private _commen: string;
  private _dacahi: Date;
  private _datcre: Date;
  private _datmod: Date;
  private _codusr: string;
  private _coefet: number;
  private _ordmin: number;
  private _ordmax: number;
  private _alamax: number;
  private _valmax: number;
  private _stamax: string;
  private _datmax: Date;
  private _aladme: number;
  private _valdme: number;
  private _stadme: string;
  private _datdme: Date;
  private _nivpic: number;
  private _datpic: Date;
  private _valpic: number;
  private _acqpic: string;
  private _nbecty: number;
  private _pubint: string;
  private _datdva: Date;
  private _semeba: number;
  private _semeha: number;
  private _persig: number;

  constructor(obj: any) {
    Object.assign(this, obj);
    this._datalm = obj.datalm ? DateUtils.newDate(obj.datalm) : null;
    this._dacahi = obj.dacahi ? DateUtils.newDate(obj.dacahi) : null;
    this._datcre = obj.datcre ? DateUtils.newDate(obj.datcre) : null;
    this._datmod = obj.datmod ? DateUtils.newDate(obj.datmod) : null;
    this._datmax = obj.datmax ? DateUtils.newDate(obj.datmax) : null;
    this._datdme = obj.datdme ? DateUtils.newDate(obj.datdme) : null;
    this._datpic = obj.datpic ? DateUtils.newDate(obj.datpic) : null;
    this._datdva = obj.datdva ? DateUtils.newDate(obj.datdva) : null;
  }


  get nomsta(): string {
    return this._nomsta;
  }

  set nomsta(value: string) {
    this._nomsta = value;
  }

  get codpar(): string {
    return this._codpar;
  }

  set codpar(value: string) {
    this._codpar = value;
  }

  get typcha(): string {
    return this._typcha;
  }

  set typcha(value: string) {
    this._typcha = value;
  }

  get nument(): string {
    return this._nument;
  }

  set nument(value: string) {
    this._nument = value;
  }

  get conver(): number {
    return this._conver;
  }

  set conver(value: number) {
    this._conver = value;
  }

  get freana(): string {
    return this._freana;
  }

  set freana(value: string) {
    this._freana = value;
  }

  get limdeh(): number {
    return this._limdeh;
  }

  set limdeh(value: number) {
    this._limdeh = value;
  }

  get limdeb(): number {
    return this._limdeb;
  }

  set limdeb(value: number) {
    this._limdeb = value;
  }

  get cripre(): number {
    return this._cripre;
  }

  set cripre(value: number) {
    this._cripre = value;
  }

  get secart(): number {
    return this._secart;
  }

  set secart(value: number) {
    this._secart = value;
  }

  get scebas(): number {
    return this._scebas;
  }

  set scebas(value: number) {
    this._scebas = value;
  }

  get scehau(): number {
    return this._scehau;
  }

  set scehau(value: number) {
    this._scehau = value;
  }

  get typval(): string {
    return this._typval;
  }

  set typval(value: string) {
    this._typval = value;
  }

  get nbpini(): number {
    return this._nbpini;
  }

  set nbpini(value: number) {
    this._nbpini = value;
  }

  get paspre(): number {
    return this._paspre;
  }

  set paspre(value: number) {
    this._paspre = value;
  }

  get ecamax(): number {
    return this._ecamax;
  }

  set ecamax(value: number) {
    this._ecamax = value;
  }

  get alarma(): string {
    return this._alarma;
  }

  set alarma(value: string) {
    this._alarma = value;
  }

  get alarmm(): string {
    return this._alarmm;
  }

  set alarmm(value: string) {
    this._alarmm = value;
  }

  get datalm(): Date {
    return this._datalm;
  }

  set datalm(value: Date) {
    this._datalm = value;
  }

  get sensal(): string {
    return this._sensal;
  }

  set sensal(value: string) {
    this._sensal = value;
  }

  get sebas1(): number {
    return this._sebas1;
  }

  set sebas1(value: number) {
    this._sebas1 = value;
  }

  get sebas2(): number {
    return this._sebas2;
  }

  set sebas2(value: number) {
    this._sebas2 = value;
  }

  get sehau1(): number {
    return this._sehau1;
  }

  set sehau1(value: number) {
    this._sehau1 = value;
  }

  get sehau2(): number {
    return this._sehau2;
  }

  set sehau2(value: number) {
    this._sehau2 = value;
  }

  get commen(): string {
    return this._commen;
  }

  set commen(value: string) {
    this._commen = value;
  }

  get dacahi(): Date {
    return this._dacahi;
  }

  set dacahi(value: Date) {
    this._dacahi = value;
  }

  get datcre(): Date {
    return this._datcre;
  }

  set datcre(value: Date) {
    this._datcre = value;
  }

  get datmod(): Date {
    return this._datmod;
  }

  set datmod(value: Date) {
    this._datmod = value;
  }

  get codusr(): string {
    return this._codusr;
  }

  set codusr(value: string) {
    this._codusr = value;
  }

  get coefet(): number {
    return this._coefet;
  }

  set coefet(value: number) {
    this._coefet = value;
  }

  get ordmin(): number {
    return this._ordmin;
  }

  set ordmin(value: number) {
    this._ordmin = value;
  }

  get ordmax(): number {
    return this._ordmax;
  }

  set ordmax(value: number) {
    this._ordmax = value;
  }

  get alamax(): number {
    return this._alamax;
  }

  set alamax(value: number) {
    this._alamax = value;
  }

  get valmax(): number {
    return this._valmax;
  }

  set valmax(value: number) {
    this._valmax = value;
  }

  get stamax(): string {
    return this._stamax;
  }

  set stamax(value: string) {
    this._stamax = value;
  }

  get datmax(): Date {
    return this._datmax;
  }

  set datmax(value: Date) {
    this._datmax = value;
  }

  get aladme(): number {
    return this._aladme;
  }

  set aladme(value: number) {
    this._aladme = value;
  }

  get valdme(): number {
    return this._valdme;
  }

  set valdme(value: number) {
    this._valdme = value;
  }

  get stadme(): string {
    return this._stadme;
  }

  set stadme(value: string) {
    this._stadme = value;
  }

  get datdme(): Date {
    return this._datdme;
  }

  set datdme(value: Date) {
    this._datdme = value;
  }

  get nivpic(): number {
    return this._nivpic;
  }

  set nivpic(value: number) {
    this._nivpic = value;
  }

  get datpic(): Date {
    return this._datpic;
  }

  set datpic(value: Date) {
    this._datpic = value;
  }

  get valpic(): number {
    return this._valpic;
  }

  set valpic(value: number) {
    this._valpic = value;
  }

  get acqpic(): string {
    return this._acqpic;
  }

  set acqpic(value: string) {
    this._acqpic = value;
  }

  get nbecty(): number {
    return this._nbecty;
  }

  set nbecty(value: number) {
    this._nbecty = value;
  }

  get pubint(): string {
    return this._pubint;
  }

  set pubint(value: string) {
    this._pubint = value;
  }

  get datdva(): Date {
    return this._datdva;
  }

  set datdva(value: Date) {
    this._datdva = value;
  }

  get semeba(): number {
    return this._semeba;
  }

  set semeba(value: number) {
    this._semeba = value;
  }

  get semeha(): number {
    return this._semeha;
  }

  set semeha(value: number) {
    this._semeha = value;
  }

  get persig(): number {
    return this._persig;
  }

  set persig(value: number) {
    this._persig = value;
  }
}
