import {Injectable} from '@angular/core';
import {environment} from '@env/environment';
import {BaseAbstractService} from '@app/core/services/base-abstract.service';
import {Station} from '@app/models/station.model';
import {Observable, Subject} from 'rxjs';

import { APP_BASE_HREF } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class StationService extends BaseAbstractService{

  _selectedStation: Station = null;
  private selectedStationSource = new Subject<Station>();
  selectedStationHandler: Observable<Station> = <Observable<Station>>this.selectedStationSource.asObservable();

  set selectedStation(station: Station) {
    this._selectedStation = station;
    this.selectedStationSource.next(station);
  }
  get selectedStation(): Station {
    return this._selectedStation;
  }

  _stations: Station[] = null;
  private stationsSource = new Subject<Station[]>();
  stationsHandler: Observable<Station[]> = <Observable<Station[]>>this.stationsSource.asObservable();

  set stations(stations: Station[]) {
    this._stations = stations;
    this.stationsSource.next(stations);
  }
  get stations(): Station[] {
    return this._stations;
  }

  get url(): string {
    return this.getApiUrl() + '/stations';
  }

  async getStations(filter: string): Promise<Station[]> {
    let stationFilter = {
      typsta:[filter]
    };
    let stations = (await this.post<any>(`${this.url}`, stationFilter)).map(json => new Station(json));
    return stations;
  }

  async getStation(nomsta: string): Promise<Station> {
    let jsonStation = await this.post<any>(`${this.url}/station`, nomsta);
    return new Station(jsonStation);
  }

}
