import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LayoutService {

  private _loading:boolean = false;

  get loading():boolean{
    return this._loading;
  }
  set loading(val:boolean){
    this._loading = val;
  }

  constructor() { }
}
