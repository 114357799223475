// Angular Modules
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {DropdownModule} from 'primeng/dropdown';
import {
  CalendarModule, CheckboxModule, ConfirmDialogModule, FileUploadModule, InputTextareaModule, MessagesModule, PasswordModule,
  PickListModule
} from 'primeng/primeng';
import {ButtonModule} from 'primeng/button';
import {TableModule} from 'primeng/table';
import {PanelModule} from 'primeng/panel';
import {ChartModule} from 'primeng/components/chart/chart';
import {MessageModule} from 'primeng/message';
import {CardModule} from 'primeng/card';
import {DialogModule} from 'primeng/dialog';
import {EditorModule} from 'primeng/editor';

// Pipes
import { PropercasePipe } from './pipes/propercase.pipe';
import {SafeHtmlPipe} from '@app/shared/pipes/safeHtml.pipe';
import {SafePipe} from '@app/shared/pipes/safe.pipe';

// Components
import { PageHeaderComponent } from './components/page-header.component';
import * as PrimeNG from "primeng/primeng";
import { NewzsComponent } from "./components/newzs/newzs.component";
import { NewzComponent } from "./components/newz/newz.component";
import { MesureDataComponent } from "./components/mesure-data/mesure-data.component";
import { HistoriqueDataComponent } from "./components/historique-data/historique-data.component";
import { AlertActionComponent } from "./components/alert-action/alert-action.component";

// Services
import {ConfirmationService} from 'primeng/api';

const MODULES: any[] = [
  CommonModule,
  FormsModule,
  HttpClientModule,
  RouterModule,
  TranslateModule,
  PrimeNG.ScrollPanelModule,
  PrimeNG.InputTextModule,
  FontAwesomeModule,
  DropdownModule,
  InputTextareaModule,
  ButtonModule,
  TableModule,
  PanelModule,
  CalendarModule,
  CheckboxModule,
  ChartModule,
  PasswordModule,
  MessagesModule,
  MessageModule,
  PickListModule,
  ConfirmDialogModule,
  CardModule,
  DialogModule,
  EditorModule,
  FileUploadModule
];

const DECLARATIONS: any[] = [
  PropercasePipe,
  PageHeaderComponent,
  SafeHtmlPipe,
  SafePipe,
  NewzsComponent,
  NewzComponent,
  MesureDataComponent,
  HistoriqueDataComponent,
   AlertActionComponent
];

const PROVIDERS: any[] = [
  ConfirmationService
];

@NgModule({
  imports: MODULES,
  declarations: DECLARATIONS,
  providers: PROVIDERS,
  exports: [...MODULES, ...DECLARATIONS]
})
export class SharedModule { }
