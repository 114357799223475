import {Component, Input, OnInit} from '@angular/core';
import {Newz} from '@app/models/newz.model';

@Component({
  selector: 'app-newz',
  templateUrl: './newz.component.html',
  styleUrls: ['./newz.component.scss']
})
export class NewzComponent implements OnInit {

  @Input() newz: Newz;

  constructor() { }

  ngOnInit() {
  }

}
