import {User} from '@app/models/user.model';
import {DateUtils} from '@app/shared/utils/date.utils';

export class Alerte{

  public codale: number;
  public datale: Date;
  public typale: string;
  public locale: string;
  public parale: string;
  public msgale: string;
  public filale: string;
  public binale: any;
  public stapec: string;
  public respec: string;
  public datpec: Date;
  public msgpec: string;
  public msgtra: string;
  public staclo: string;
  public resclo: string;
  public datclo: Date;
  public datcre: Date;
  public datmod: Date;
  public codusr: string;

  public userRespec: User;

  public stationName: string = null;

  constructor(obj: any) {
    Object.assign(this, obj);

    this.codale = obj.codale ? obj.codale : null;
    this.datale = obj.datale ? obj.datale : null;
    this.typale = obj.typale ? obj.typale : null;
    this.locale = obj.locale ? obj.locale : null;
    this.parale = obj.parale ? obj.parale : null;
    this.msgale = obj.msgale ? obj.msgale : null;
    this.filale = obj.filale ? obj.filale : null;
    this.binale = obj.binale ? obj.binale : null;
    this.stapec = obj.stapec ? obj.stapec : null;
    this.respec = obj.respec ? obj.respec : null;
    this.msgpec = obj.msgpec ? obj.msgpec : null;
    this.msgtra = obj.msgtra ? obj.msgtra : null;
    this.staclo = obj.staclo ? obj.staclo : null;
    this.resclo = obj.resclo ? obj.resclo : null;
    this.codusr = obj.codusr ? obj.codusr : null;

    this.datpec = obj.datpec ? DateUtils.newDate(obj.datpec) : null;
    this.datclo = obj.datclo ? DateUtils.newDate(obj.datclo) : null;
    this.datcre = obj.datcre ? DateUtils.newDate(obj.datcre) : null;
    this.datmod = obj.datmod ? DateUtils.newDate(obj.datmod) : null;
    this.userRespec = obj.userRespec ? new User(obj.userRespec) : null;
  }

}
