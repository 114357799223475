import { Injectable } from '@angular/core';
import {Message} from 'primeng/api';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  private _msgs: Message[] = [];

  constructor() { }

  public get msgs(): Message[] {
    return this._msgs;
  }

  public set msgs(value: Message[]) {
    this._msgs = value;
  }

  async addSingleError(error: any){
    this._msgs.splice(0, this._msgs.length);
    let summary = "Erreur";

    let errorDetail = '';
    if(typeof(error) == typeof("string")){
      errorDetail = error;
    }
    else if(typeof(error.error) == typeof("string")){
      errorDetail = error.error;
    }else{
      errorDetail = error.message;
    }
    this._msgs.push({severity: 'error', summary: summary, detail: errorDetail});
  }

  async addSingleInfo(summary: string, info: string){
    this._msgs.splice(0, this._msgs.length);
    this._msgs.push({severity: 'info', summary: summary, detail: info});
  }

  async addSingleSuccess(summary: string, info: string){
    this._msgs.splice(0, this._msgs.length);
    this._msgs.push({severity: 'success', summary: summary, detail: info});
  }

  addMultipleError(msgs: any[]){
    this._msgs.splice(0, this._msgs.length);
    if(msgs && msgs.length){
      msgs.forEach(msg =>{
        this.addSingleError(msg.detail);
      })
    }
  }
}
