import {DateUtils} from '@app/shared/utils/date.utils';

export class Role {

  public logusr: string;
  public codope: string;
  public codusr: string;
  public datcre: Date;
  public datmod: Date;


  constructor(obj:any) {
    Object.assign(this,obj);
    this.datcre = obj.datCre ? DateUtils.newDate(obj.datCre) : null;
    this.datmod = obj.datMod ? DateUtils.newDate(obj.datMod) : null;
  }

}
