import {DateUtils} from '@app/shared/utils/date.utils';

export class Evenem {
  public ideven: number;
  public nomsta: string;
  public codpar: string;
  public dateev: Date;
  public commen: string;
  public filepa: string;
  public filebi: any;
  public codusr: string;

  public parametre: string;

  constructor(obj: any) {
    Object.assign(this, obj);
    this.dateev = obj.dateev ? DateUtils.newDate(obj.dateev) : null;
  }


}
