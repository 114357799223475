import * as moment from 'moment';

export class DateUtils {

  static FORMAT = {
    BE: 'DD/MM/YYYY'
  };

  static newDate(date: any): Date {
    return moment(date).toDate();
  }

  static newDateWithFormat(date: any, format: string): Date {
    return moment(date, format).toDate();
  }

  static formatDate(date: Date, format: string): string {
    return moment(date).format(format);
  }

}
