import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {User} from '@app/models/user.model';
import {BaseAbstractService} from '@app/core/services/base-abstract.service';
import {environment} from '@env/environment';

@Injectable({
  providedIn: 'root'
})
export class UserService extends BaseAbstractService{

  _user: User = null;
  private userSource = new Subject<User>();
  userHandler: Observable<User> = <Observable<User>>this.userSource.asObservable();

  set user(user: User) {
    this._user = user;
    this.userSource.next(user);
  }
  get user(): User {
    return this._user;
  }

  _selectedUser: User = null;
  private selectedUserSource = new Subject<User>();
  selectedUserHandler: Observable<User> = <Observable<User>>this.selectedUserSource.asObservable();

  set selectedUser(user: User) {
    this._selectedUser = user;
    this.selectedUserSource.next(user);
  }
  get selectedUser(): User {
    return this._selectedUser;
  }


  get url(): string {
    return this.getApiUrl() + '/users';
  }

  async getUsers(): Promise<User[]>{
    let users = (await this.get<any>(`${this.url}`)).map(json => new User(json));
    return users;
  }

  async editUser(editUser: User, originalUser: User): Promise<User>{
    let userJson = await this.post<any>(`${this.url}/editUser`, [editUser, originalUser]);
    return new User(userJson);
  }

  async createUser(user: User): Promise<User>{
    let userJson = await this.post<any>(`${this.url}/createUser`, user);
    return new User(userJson);
  }

  find(login: string): Promise<User> {
    return this.get(`${this.url}/user/${login}`);
  }

  async deleteUser(user: User):Promise<boolean>{
    let ret = await this.post<any>(`${this.url}/deleteUser`, user);
    return ret;
  }

  resetMail(mail:String){
    this.post<any>(`${this.url}/forgetEmail`, mail);
  }

}
