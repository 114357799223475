import {Component, Input, OnInit} from '@angular/core';
import {Newz} from '@app/models/newz.model';
import {NewzService} from '@app/core/services/newz.service';

@Component({
  selector: 'app-newzs',
  templateUrl: './newzs.component.html',
  styleUrls: ['./newzs.component.scss']
})
export class NewzsComponent implements OnInit {

  @Input() newzs: Newz[];

  constructor() { }

  ngOnInit() {
  }

}
