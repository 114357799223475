import {Unite} from '@app/models/unite.model';
import {Stapar} from '@app/models/stapar.model';
import {DateUtils} from '@app/shared/utils/date.utils';

export class Parametre {

  private _codpar: string;          //CODE PARAMETRE
  private _stapars: Stapar[];
  private _libpar: string;          //LIBELLE
  private _coduni: Unite;           //CODE UNITE
  private _nbdecs: number;          //DECIMALES SIGNIFICATIVES
  private _typcal: string;          //TYPE CALCUL PREVISIONNEL
  private _datcre: Date;            //DATE DE CREATION
  private _datmod: Date;            //DATE MODIFICATION
  private _codusr: string;          //CODE UTILISATEUR
  private _typpar: string;          //
  private _codede: string;          //
  private _perce1: number;          //ZONE DE PERCENTILE 1
  private _perce2: number;          //ZONE DE PERCENTILE 2
  private _perce3: number;          //ZONE DE PERCENTILE 3
  private _perce4: number;          //ZONE DE PERCENTILE 4
  private _perce5: number;          //ZONE DE PERCENTILE 5
  private _perce6: number;          //ZONE DE PERCENTILE 6
  private _sbused: string;          //SEUIL BAS UTILISE
  private _sb1: number;             //SEUIL BAS 1
  private _sb2: number;             //SEUIL BAS 2
  private _sb3: number;             //SEUIL BAS 3
  private _sb4: number;             //SEUIL BAS 4
  private _shused: string;          //SEUIL HAUT UTILISE
  private _sh1: number;             //SEUIL HAUT 1
  private _sh2: number;             //SEUIL HAUT 2
  private _sh3: number;             //SEUIL HAUT 3
  private _sh4: number;             //SEUIL HAUT 4
  private _uniint: string;          //Code unité à utiliser sur Internet
  private _coeint: number;          //Coefficient de conversion à utiliser sur Internet
  private _seqrap: number;          //Ordre de tri rapport qualité
  private _sa_b: number;            //Seuil d'alerte BAS
  private _sa_h: number;            //Seuil d'alerte HAUT
  private _abr_rap: string;         //Abreviation rapport classe de qualité

  constructor(obj: any) {
    Object.assign(this, obj);
    this._datcre = obj.datcre ? DateUtils.newDate(obj.datcre) : null;
    this._datmod = obj.datmod ? DateUtils.newDate(obj.datmod) : null;
    this._stapars = obj.stapars ? obj.stapars.map(stapar => new Stapar(stapar)) : null;
    this._coduni = obj.coduni ? new Unite(obj.coduni) : null;
  }


  get codpar(): string {
    return this._codpar;
  }

  set codpar(value: string) {
    this._codpar = value;
  }


  get stapars(): Stapar[] {
    return this._stapars;
  }

  set stapars(value: Stapar[]) {
    this._stapars = value;
  }

  get libpar(): string {
    return this._libpar;
  }

  set libpar(value: string) {
    this._libpar = value;
  }

  get coduni(): Unite {
    return this._coduni;
  }

  set coduni(value: Unite) {
    this._coduni = value;
  }

  get nbdecs(): number {
    return this._nbdecs;
  }

  set nbdecs(value: number) {
    this._nbdecs = value;
  }

  get typcal(): string {
    return this._typcal;
  }

  set typcal(value: string) {
    this._typcal = value;
  }

  get datcre(): Date {
    return this._datcre;
  }

  set datcre(value: Date) {
    this._datcre = value;
  }

  get datmod(): Date {
    return this._datmod;
  }

  set datmod(value: Date) {
    this._datmod = value;
  }

  get codusr(): string {
    return this._codusr;
  }

  set codusr(value: string) {
    this._codusr = value;
  }

  get typpar(): string {
    return this._typpar;
  }

  set typpar(value: string) {
    this._typpar = value;
  }

  get codede(): string {
    return this._codede;
  }

  set codede(value: string) {
    this._codede = value;
  }

  get perce1(): number {
    return this._perce1;
  }

  set perce1(value: number) {
    this._perce1 = value;
  }

  get perce2(): number {
    return this._perce2;
  }

  set perce2(value: number) {
    this._perce2 = value;
  }

  get perce3(): number {
    return this._perce3;
  }

  set perce3(value: number) {
    this._perce3 = value;
  }

  get perce4(): number {
    return this._perce4;
  }

  set perce4(value: number) {
    this._perce4 = value;
  }

  get perce5(): number {
    return this._perce5;
  }

  set perce5(value: number) {
    this._perce5 = value;
  }

  get perce6(): number {
    return this._perce6;
  }

  set perce6(value: number) {
    this._perce6 = value;
  }

  get sbused(): string {
    return this._sbused;
  }

  set sbused(value: string) {
    this._sbused = value;
  }

  get sb1(): number {
    return this._sb1;
  }

  set sb1(value: number) {
    this._sb1 = value;
  }

  get sb2(): number {
    return this._sb2;
  }

  set sb2(value: number) {
    this._sb2 = value;
  }

  get sb3(): number {
    return this._sb3;
  }

  set sb3(value: number) {
    this._sb3 = value;
  }

  get sb4(): number {
    return this._sb4;
  }

  set sb4(value: number) {
    this._sb4 = value;
  }

  get shused(): string {
    return this._shused;
  }

  set shused(value: string) {
    this._shused = value;
  }

  get sh1(): number {
    return this._sh1;
  }

  set sh1(value: number) {
    this._sh1 = value;
  }

  get sh2(): number {
    return this._sh2;
  }

  set sh2(value: number) {
    this._sh2 = value;
  }

  get sh3(): number {
    return this._sh3;
  }

  set sh3(value: number) {
    this._sh3 = value;
  }

  get sh4(): number {
    return this._sh4;
  }

  set sh4(value: number) {
    this._sh4 = value;
  }

  get uniint(): string {
    return this._uniint;
  }

  set uniint(value: string) {
    this._uniint = value;
  }

  get coeint(): number {
    return this._coeint;
  }

  set coeint(value: number) {
    this._coeint = value;
  }

  get seqrap(): number {
    return this._seqrap;
  }

  set seqrap(value: number) {
    this._seqrap = value;
  }

  get sa_b(): number {
    return this._sa_b;
  }

  set sa_b(value: number) {
    this._sa_b = value;
  }

  get sa_h(): number {
    return this._sa_h;
  }

  set sa_h(value: number) {
    this._sa_h = value;
  }

  get Abr_rap(): string {
    return this._abr_rap;
  }

  set Abr_rap(value: string) {
    this._abr_rap = value;
  }



  get stapar(): Stapar {
    if(this.stapars && this.stapars.length>0){
      return this.stapars[0];
    }
    return null;
  }

  get limdeb(): number{
    if(this.stapar){
      let calculateVal = this.stapar.limdeb*(this.coeint ? this.coeint : 1);
      return calculateVal;
    }
  }
  get limdeh(): number{
    if(this.stapar){
      let calculateVal = this.stapar.limdeh*(this.coeint ? this.coeint : 1);
      return calculateVal;
  }
  }
}
