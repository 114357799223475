import {Component, Input, OnInit} from '@angular/core';
import {trigger, state, style, transition, animate} from '@angular/animations';
import {MenuItem} from 'primeng/primeng';
import {LayoutComponent} from "./layout.component";
import {StationService} from '@app/core/services/station.service';
import {UserService} from '@app/core/services/user.service';
import {Station} from '@app/models/station.model';
import {User} from '@app/models/user.model';

@Component({
  selector: 'app-menu',
  template: `
    <ul app-submenu [item]="model" root="true" class="ultima-menu ultima-main-menu clearfix"
        [reset]="reset" visible="true" parentActive="true"></ul>
  `
})
export class LayoutMenuComponent implements OnInit {

  @Input() reset: boolean;

  model: any[];

  constructor(public app: LayoutComponent,
              public stationService: StationService,
              public userService: UserService) {}

  ngOnInit() {
    this.stationService.selectedStationHandler.subscribe((station: Station) => {
      this.updateNav();
    });
    this.userService.userHandler.subscribe((user: User) => {
      this.updateNav();
    });
    if(this.userService.session.user){
      this.userService.user = new User(this.userService.session.user);
    }
    this.updateNav();
  }

  updateNav() {
    this.model = [];

    let accueilMenu:MenuItem = {
      label: 'Infos',
      icon: 'dashboard',
      routerLink: ['/home'],
      items: [
        {label: 'Introduction', icon: 'desktop_mac', routerLink: ['/pages/intro']},
        {label: 'Réseau de mesures', icon: 'info', routerLink: ['/pages/reseau']},
        {label: 'Actualités', icon: 'info', routerLink: ['/pages/actualites']},
        {label: 'Publications', icon: 'info', routerLink: ['/pages/publications']},
        {label: 'Textes législatifs', icon: 'info', routerLink: ['/pages/legislatifs']}
      ]
    };

    let stationsMenu:MenuItem = {
      label: 'Données',
      icon: 'view_list',
      items: [
        {label: 'Carte', icon: 'map', routerLink: ['/stations/map']},
        {label: 'Stations', icon: 'list', routerLink: ['/stations']}
      ]
    };

    let stationMenu:MenuItem = {
      label: 'Description',
      icon: 'info',
      routerLink: ['/stations/station']
    };
    if(this.userService.user) {
      if(this.userService.user.hasRoleEvent() && this.userService.user.hasRoleNews()) {
        stationMenu = {
          label: 'Description',
          icon: 'info',
          routerLink: ['/stations/station'],
          items: [
            {label: 'Gestion des évenements', icon: 'info', routerLink: ['/stations/station/events']},
            {label: 'Gestion des news', icon: 'info', routerLink: ['/stations/station/news']},
          ]
        };
      }
      else if(this.userService.user.hasRoleEvent()) {
        stationMenu = {
          label: 'Description',
          icon: 'info',
          routerLink: ['/stations/station'],
          items: [
            {label: 'Gestion des évenements', icon: 'info', routerLink: ['/stations/station/events']},
          ]
        };
      }
      else if(this.userService.user.hasRoleNews()){
        stationMenu = {
          label: 'Description',
          icon: 'info',
          routerLink: ['/stations/station'],
          items: [
            {label: 'Gestion des news', icon: 'info', routerLink: ['/stations/station/news']},
          ]
        };
      }
    }

    let stationDonnees:MenuItem = {
      label: 'Données',
      icon: 'pageview',
      items: this.userService.user && this.userService.user.hasRoleQuart() ? [
        {label: 'Catalogue', icon: 'desktop_mac', routerLink: ['/stations/station/data/catalogue']},
        {label: 'Mesures 2h', icon: 'info', routerLink: ['/stations/station/data/mesure/2h']},
        {label: 'Mesures 15m', icon: 'info', routerLink: ['/stations/station/data/mesure/15m']},
        {label: 'Historiques journaliers', icon: 'grid_on', routerLink: ['/stations/station/data/historique/journa']},
        {label: 'Historiques mensuels', icon: 'grid_on', routerLink: ['/stations/station/data/historique/mensuel']},
        {label: 'Historiques annuels', icon: 'grid_on', routerLink: ['/stations/station/data/historique/annuel']}

      ]:[{label: 'Catalogue', icon: 'desktop_mac', routerLink: ['/stations/station/data/catalogue']},
        {label: 'Mesures 2h', icon: 'info', routerLink: ['/stations/station/data/mesure/2h']},
        {label: 'Historiques journaliers', icon: 'grid_on', routerLink: ['/stations/station/data/historique/journa']},
        {label: 'Historiques mensuels', icon: 'grid_on', routerLink: ['/stations/station/data/historique/mensuel']},
        {label: 'Historiques annuels', icon: 'grid_on', routerLink: ['/stations/station/data/historique/annuel']}]
    };

    let alertsMenu:MenuItem = {
      label: 'Alertes',
      icon: 'warning',
      routerLink: ['/alerts']
    };

    this.model.push(accueilMenu);

    this.model.push(stationsMenu);

    if(this.stationService.selectedStation){
      this.model.push(stationMenu);

      this.model.push(stationDonnees);
    }
    this.model.push({label: 'Contact', icon: 'mail', routerLink: ['/contact']});
    if(this.userService.user && this.userService.user.hasRoleTreat()){
      this.model.push(alertsMenu);
    }
    if(this.userService.user){
      let securiteMenu:MenuItem = {
        label: 'Sécurité',
        icon: 'build',
        items: this.userService.user.hasRoleAdmin() ? [
          {label: 'Mon profil', icon: 'info', routerLink: ['/users/user']},
          {label: 'Administration des utilisateurs', icon: 'info', routerLink: ['/users']},
        ]: [
          {label: 'Mon profil', icon: 'info', routerLink: ['/users/user']}
        ]
      };
      this.model.push(securiteMenu);
    }

  }

}

@Component({
  /* tslint:disable:component-selector */
  selector: '[app-submenu]',
  /* tslint:enable:component-selector */
  template: `
    <ng-template ngFor let-child let-i="index" [ngForOf]="(root ? item : item.items)">
      <li [ngClass]="{'active-menuitem': isActive(i)}" [class]="child.badgeStyleClass" *ngIf="child.visible === false ? false : true">
        <a [href]="child.url||'#'" (click)="itemClick($event,child,i)" (mouseenter)="onMouseEnter(i)"
           class="ripplelink" *ngIf="!child.routerLink"
           [attr.tabindex]="!visible ? '-1' : null" [attr.target]="child.target">
          <i *ngIf="child.icon" class="material-icons">{{child.icon}}</i>
          <span>{{child.label}}</span>
          <span class="menuitem-badge" *ngIf="child.badge">{{child.badge}}</span>
          <i class="material-icons submenu-icon" *ngIf="child.items">keyboard_arrow_down</i>
        </a>

        <a (click)="itemClick($event,child,i)" (mouseenter)="onMouseEnter(i)" class="ripplelink" *ngIf="child.routerLink"
           [routerLink]="child.routerLink" routerLinkActive="active-menuitem-routerlink"
           [routerLinkActiveOptions]="{exact: true}" [attr.tabindex]="!visible ? '-1' : null" [attr.target]="child.target">
          <i *ngIf="child.icon" class="material-icons">{{child.icon}}</i>
          <span>{{child.label}}</span>
          <span class="menuitem-badge" *ngIf="child.badge">{{child.badge}}</span>
          <i class="material-icons submenu-icon" *ngIf="child.items">keyboard_arrow_down</i>
        </a>
        <div class="layout-menu-tooltip">
          <div class="layout-menu-tooltip-arrow"></div>
          <div class="layout-menu-tooltip-text">{{child.label}}</div>
        </div>
        <ul app-submenu [item]="child" *ngIf="child.items" [visible]="isActive(i)" [reset]="reset" [parentActive]="isActive(i)"
            [@children]="(app.isSlim()||app.isHorizontal())&&root ? isActive(i) ?
                    'visible' : 'hidden' : isActive(i) ? 'visibleAnimated' : 'hiddenAnimated'"></ul>
      </li>
    </ng-template>
  `,
  animations: [
    trigger('children', [
      state('hiddenAnimated', style({
        height: '0px'
      })),
      state('visibleAnimated', style({
        height: '*'
      })),
      state('visible', style({
        height: '*',
        'z-index': 100
      })),
      state('hidden', style({
        height: '0px',
        'z-index': '*'
      })),
      transition('visibleAnimated => hiddenAnimated', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)')),
      transition('hiddenAnimated => visibleAnimated', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)'))
    ])
  ]
})

export class LayoutSubMenuComponent {

  @Input() item: MenuItem;

  @Input() root: boolean;

  @Input() visible: boolean;

  _reset: boolean;

  _parentActive: boolean;

  activeIndex: number;

  constructor(public app: LayoutComponent) {}

  itemClick(event: Event, item: MenuItem, index: number) {
    if (this.root) {
      this.app.menuHoverActive = !this.app.menuHoverActive;
    }

    // avoid processing disabled items
    if (item.disabled) {
      event.preventDefault();
      return true;
    }

    // activate current item and deactivate active sibling if any
    this.activeIndex = (this.activeIndex === index) ? null : index;

    // execute command
    if (item.command) {
      item.command({originalEvent: event, item: item});
    }

    // prevent hash change
    if (item.items || (!item.url && !item.routerLink)) {
      setTimeout(() => {
        this.app.layoutMenuScrollerViewChild.moveBar();
      }, 450);
      event.preventDefault();
    }

    // hide menu
    if (!item.items) {
      if (this.app.isHorizontal() || this.app.isSlim()) {
        this.app.resetMenu = true; } else {
        this.app.resetMenu = false; }

      this.app.overlayMenuActive = false;
      this.app.staticMenuMobileActive = false;
      this.app.menuHoverActive = !this.app.menuHoverActive;
    }
  }

  onMouseEnter(index: number) {
    if (this.root && this.app.menuHoverActive && (this.app.isHorizontal() || this.app.isSlim())
      && !this.app.isMobile() && !this.app.isTablet()) {
      this.activeIndex = index;
    }
  }

  isActive(index: number): boolean {
    return this.activeIndex === index;
  }

  @Input() get reset(): boolean {
    return this._reset;
  }

  set reset(val: boolean) {
    this._reset = val;

    if (this._reset && (this.app.isHorizontal() || this.app.isSlim())) {
      this.activeIndex = null;
    }
  }

  @Input() get parentActive(): boolean {
    return this._parentActive;
  }

  set parentActive(val: boolean) {
    this._parentActive = val;

    if (!this._parentActive) {
      this.activeIndex = null;
    }
  }
}
