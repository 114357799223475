export class Layout {
    layoutCompact: boolean = true;
    layoutMode: string = 'STATIC'; // STATIC, OVERLAY, HORIZONTAL, SLIM
    darkMenu: boolean = false;
    profileMode: string = 'inline'; // inline, top
    profileView: boolean = true;
    demoMenu: boolean = false;
    layoutMenu: boolean = false;
    globalSearch: boolean = true;
    footerView: boolean = true;
    settingsView: boolean = true;
    msgView: boolean = true;
    notiView: boolean = true;
    rightPanel: boolean = true;
    breadcrumb: boolean = false;
    avatar: string = "avatar.png";
}
