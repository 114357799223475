export default class Utils {

  static makeUniqueArray(sources: any[]):any[] {
    let results:any[] = [];

    sources.forEach(source => {
      if(results.findIndex(result=>{
        if(typeof result.isEqual === "function"){
          return result.isEqual(source);
        }else{
          return result === source;
        }
      }) == -1){
      results.push(source);
    }
    });

    return results;
  }

  static daysBetween ( dateStart:Date, dateEnd:Date ):number {   //Get 1 day in milliseconds
    let one_day=1000*60*60*24;
    // Convert both dates to milliseconds
    let date1_ms = dateStart.getTime();
    let date2_ms = dateEnd.getTime();
    // Calculate the difference in milliseconds
    let difference_ms = date2_ms - date1_ms;
    // Convert back to days and return
    return Math.round(difference_ms/one_day);
  }

  static downloadBlob(downloadUrl: string, title: string, response: Response){
    if (window.navigator && window.navigator.msSaveOrOpenBlob && response.body) {
      window.navigator.msSaveOrOpenBlob(response.body, title);
    } else {
      let a: HTMLAnchorElement = document.createElement('a') as HTMLAnchorElement;
      a.href = downloadUrl;
      a.download = title;
      document.body.appendChild(a);
      a.click();
    }

  }
}
