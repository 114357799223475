import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs/Observable';
import {Injectable} from '@angular/core';
import {StationService} from '@app/core/services/station.service';
import {Session} from '@app/app.session';
import {UserService} from '@app/core/services/user.service';

@Injectable()
export class NoAuthGuard implements CanActivate {

  constructor(
    private router: Router,
    private userService: UserService,
    private session: Session
  ){}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
    if (this.userService.user == null || this.userService.session.user == null) {
      return true;
    }

    this.router.navigate(['/home']);
    return false;
  }

}
