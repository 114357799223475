import {Injectable} from '@angular/core';
import {BaseAbstractService} from '@app/core/services/base-abstract.service';
import {Evenem} from '@app/models/evenem.model';
import {Observable, Subject} from 'rxjs';
import {environment} from '@env/environment';
import Utils from '@app/shared/services/utils';

@Injectable({
  providedIn: 'root'
})
export class EvenementService extends BaseAbstractService{

  _evenems: Evenem[] = null;
  private evenemsSource = new Subject<Evenem[]>();
  evenemsHandler: Observable<Evenem[]> = <Observable<Evenem[]>>this.evenemsSource.asObservable();

  set evenems(evenem: Evenem[]) {
    this._evenems = evenem;
    this.evenemsSource.next(evenem);
  }
  get evenems(): Evenem[] {
    return this._evenems;
  }

  _evenem: Evenem = null;
  private evenemSource = new Subject<Evenem>();
  evenemHandler: Observable<Evenem> = <Observable<Evenem>>this.evenemSource.asObservable();

  set evenem(evenem: Evenem) {
    this._evenem = evenem;
    this.evenemSource.next(evenem);
  }
  get evenem(): Evenem {
    return this._evenem;
  }

  get url(): string {
    return this.getApiUrl() + '/evenems';
  }

  async getEvenem(): Promise<Evenem[]>{
    let evenems = (await this.get<any>(`${this.url}`)).map(json => new Evenem(json));
    return evenems;
  }

  async getEvenemsFiltered(nomsta: string, code: string, dateStart: Date, dateEnd: Date): Promise<Evenem[]>{
    let data = {
      nomsta : nomsta,
      code : code,
      dateStart : dateStart,
      dateEnd : dateEnd
    };
    let evenems = (await this.post<any>(`${this.url}`, data)).map(json => new Evenem(json));
    return evenems;
  }

  async saveEvenem(evenem: Evenem):Promise<Evenem>{
    if(evenem.codpar==""){
      evenem.codpar = null;
    }
    let evenemJson = await this.post<any>(`${this.url}/evenem/save`, evenem);
    return new Evenem(evenemJson);
  }

  async updateEvenem(evenem: Evenem):Promise<Evenem>{
    if(evenem.codpar==""){
      evenem.codpar = null;
    }
    let evenemJson = await this.post<any>(`${this.url}/evenem/update`, evenem);
    return new Evenem(evenemJson);
  }

  async deleteEvenem(evenem: Evenem):Promise<boolean>{
    if(evenem.codpar==""){
      evenem.codpar = null;
    }
    await this.post<any>(`${this.url}/evenem/delete`, evenem);
    return true;
  }

  async download(evenem: Evenem):Promise<any>{
    let promise: Promise<any> = new Promise(async (resolve, reject) => {
      try{
        this.layoutService.loading = true;
        let response:Response =  await this.postBlob(`${this.url}/download`, evenem.ideven);
        let downloadUrl= URL.createObjectURL(response.body);

        let filename = response.headers.get('filename') ? response.headers.get('filename') : evenem.filepa;

        Utils.downloadBlob(downloadUrl, filename, response);

      }catch(error){
        this.notificationService.addSingleError('Impossible de télécharger les données actuellement');
        reject(error);
      }
      finally {
        this.layoutService.loading = false;
      }
    });
    return promise;
  }
}
