import { Injectable } from '@angular/core';
import {User} from '@app/models/user.model';

@Injectable({ providedIn: 'root' })
export class Session
{
  private STORAGE_USER: string = "user";
  private STORAGE_TOKEN: string = "token";

  get user() : User {
    return JSON.parse(sessionStorage.getItem(this.STORAGE_USER));
  }

  set user(user: User) {
    sessionStorage.setItem(this.STORAGE_USER, JSON.stringify(user));
  }

  get token() : string {
    return sessionStorage.getItem(this.STORAGE_TOKEN)
  }

  set token(token: string) {
    sessionStorage.setItem(this.STORAGE_TOKEN, token);
  }

  get isLogged(): boolean {
    return !!sessionStorage.getItem(this.STORAGE_TOKEN);
  }

  public logout(): void {
    sessionStorage.removeItem(this.STORAGE_USER);
    sessionStorage.removeItem(this.STORAGE_TOKEN);
  }

  get isAdmin(): boolean{
    return JSON.parse(sessionStorage.getItem(this.STORAGE_USER)).authorities.indexOf('ROLE_ADMIN') > -1;
  }

}
