import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs/Observable';
import {Injectable} from '@angular/core';
import {StationService} from '@app/core/services/station.service';

@Injectable()
export class StationGuard implements CanActivate {

  constructor(private router: Router,
              private stationService: StationService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
    if (this.stationService.selectedStation) {
      return true;
    }

    this.router.navigate(['/home']);
    return false;
  }

}
