import { Injectable } from '@angular/core';
import {BaseAbstractService} from '@app/core/services/base-abstract.service';
import {environment} from '@env/environment';

@Injectable({
  providedIn: 'root'
})
export class MyAuthService extends BaseAbstractService{

  get url(): string {
    return this.getApiUrl() + '/auth/token';
  }
}
