import {Role} from '@app/models/role.model';
import {DateUtils} from '@app/shared/utils/date.utils';

export class User {

  public logusr?: string;      //Compte de connexion
  public passwd?: string;      //Mot de passe
  public libell?: string;      //Libellé
  public adrema?: string;      //Adresse email
  public numgsm?: string;      //Numéro de GSM
  public remarq?: string;      //Remarque
  public usract?: string;      //Utilisateur actif
  public codusr?: string;      //Utilisateur de création ou modification
  public datcre?: Date;        //Date de création
  public datmod?: Date;        //Date de dernière modification

  public roles?: Role[];

  constructor(obj:any) {
    Object.assign(this,obj);
    this.datcre = obj.datcre ? DateUtils.newDate(obj.datcre) : null;
    this.datmod = obj.datmod ? DateUtils.newDate(obj.datmod) : null;
    this.roles = obj.roles ? obj.roles.map(role => new Role(role)) : null;
  }

  public hasRoleAdmin():boolean{
    return this.hasRole("admin");
  }

  public hasRoleEmail():boolean{
    return this.hasRole("email");
  }

  public hasRoleEvent():boolean{
    return this.hasRole("event");
  }

  public hasRoleNews():boolean{
    return this.hasRole("news");
  }

  public hasRoleTreat():boolean{
    return this.hasRole("treat");
  }

  public hasRoleConfirm():boolean{
    return this.hasRole("confirm");
  }

  public hasRoleQuart():boolean{
    return this.hasRole("quart");
  }

  private hasRole(roleName: string):boolean{
    if(this.roles && this.roles.length > 0){
      let role = this.roles.find(role => role.codope == roleName);
      return role !== undefined;
    }
    return false;
  }

}
