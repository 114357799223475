import {Alerte} from '@app/models/alerte.model';
import {Parametre} from '@app/models/parametre.model';
import {DateUtils} from '@app/shared/utils/date.utils';

export class Indmed{
  public nomsta: string;
  public codpar: string;
  public datind: string;
  public valind: number;
  public valcom: number;
  public depseu: string;
  public valseu: number;
  public aleind: string;
  public codale: number;
  public valp10: number;
  public valp95: number;
  public annper: string;
  public datcre: Date;
  public datmod: Date;
  public codusr: string;
  public alerte: Alerte;
  public parametre: Parametre;

  public stationName: string = null;

  constructor(obj: any) {
    Object.assign(this, obj);
    this.datcre = obj.datcre ? DateUtils.newDate(obj.datcre) : null;
    this.datmod = obj.datmod ? DateUtils.newDate(obj.datmod) : null;
    this.alerte = obj.alerte ? new Alerte(obj.alerte) : null;
    this.parametre = obj.parametre ? new Parametre(obj.parametre) : null;
  }

}
