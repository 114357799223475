import 'rxjs/add/observable/throw';
import {Observable} from "rxjs/Observable";

import {Inject, Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {environment} from '@env/environment';
import {Session} from '@app/app.session';
import {Router} from '@angular/router';
import {User} from '@app/models/user.model';
import {APP_BASE_HREF} from '@angular/common';

@Injectable()
export class AuthService {

  protected _url: string;

  constructor(
    public baseHref: string,
    private router: Router,
    private http: HttpClient,
    public session: Session
  ){
      this._url = environment.apiUrl + '/authenticate';
  }

  get url(): string {
    if(environment.production){
      return this.baseHref + this._url;
    } else {
      return this._url;
    }
  }

  public getApiUrl(): string {
    if(environment.production){
      return this.baseHref + environment.apiUrl;
    } else {
      return environment.apiUrl;
    }
  }

  public login(credentials: User) : Observable<any>{
    let headers:HttpHeaders = new HttpHeaders();

    headers.append("Accept", "application/json");
    headers.append("Content-Type", "application/json");

    // return this.http.post(this.url, credentials, {headers: headers});
    return this.http.post(this.url, credentials);
  }


  public logout() {
    this.session.logout();
    return this.http.delete(this.url);
  }

  public isLogged():boolean{
    if(this.session.user){
      return true;
    }
    return false;
  }
}
