import {Tcode} from '@app/models/tcode.model';
import {Newz} from '@app/models/newz.model';
import {Parametre} from '@app/models/parametre.model';
import {DateUtils} from '@app/shared/utils/date.utils';

export class Station {

  private _nomsta: string;             //NOM DE LA STATION
  private _numsta: string;             //NUMERO DE STATION
  private _typsta: string;             //TYPE DE STATION
  private _adrsta: string;             //ADRESSE STATION
  private _possta: string;             //CODE POSTAL STATION
  private _locsta: string;             //LOCALITE STATION
  private _provin: string;             //PROVINCE
  private _basver: string;             //BASSIN VERSANT
  private _rivier: string;             //COURS EAU
  private _pfxsta: string;             //PREFIXE TELEPHONIQUE
  private _telsta: string;             //TELEPHONE
  private _staope: string;             //STATION OPERATIONNELLE
  private _loglab: number;             //LONGITUDE
  private _latlab: number;             //LATITUDE
  private _misser: Date;               //DATEMISEENSERVICE
  private _carign: string;             //NUMERODECARTEIGN
  private _alarms: string;             //ALARMESYSTEME
  private _alarma: string;             //ALARMEANALYSEUR
  private _alarmm: string;             //ALARMEMESURE
  private _coddeb: string;             //CODEDEBIT
  private _commen: string;             //COMMENTAIRES
  private _cartrw: string;             //CARTEREGIONWALLONNE
  private _phosta: string;             //PHOTOSTATION
  private _imaign: string;             //PLANIGN
  private _vueaer: string;             //VUEAERIENNE
  private _datcre: Date;               //DATEDECREATION
  private _datmod: Date;               //DATEMODIFICATION
  private _codusr: string;             //CODEUTILISATEUR
  private _te2sta: string;             //TELEPHONE2
  private _pubint: string;             //APUBLIERSURINTERNET
  private _basoff: string;             //BASSINOFFICIEL
  private _tcodes: Tcode[];
  private _codins: string;             //CODEINS
  private _datpub: Date;               //Datemaximumdepublicationdesdonnéesdeuxheures.
  private _seqrap: number;             //Ordred'affichagedelastationdanslerapport"ClassesdeQualité"CQE
  private _nomsta_min: string;         //NomdelastationenminusculesetavecaccentspourrapportCQE

  private _newzs: Newz[];
  private _parametres: Parametre[];


  constructor(obj: any) {
    Object.assign(this, obj);
    this._misser = obj.misser ? DateUtils.newDate(obj.misser) : null;
    this._datcre = obj.datcre ? DateUtils.newDate(obj.datcre) : null;
    this._datmod = obj.datmod ? DateUtils.newDate(obj.datmod) : null;
    this._datpub = obj.datpub ? DateUtils.newDate(obj.datpub) : null;
    this._tcodes = obj.tcodes ? obj.tcodes.map(t => new Tcode(t)) : null;
    this._newzs = obj.newzs ? obj.newzs.map(n => new Newz(n)) : null;
    this._parametres = obj.parametres ? obj.parametres.map(p => new Parametre(p)) : null;
  }


  get nomsta(): string {
    return this._nomsta;
  }

  set nomsta(value: string) {
    this._nomsta = value;
  }

  get numsta(): string {
    return this._numsta;
  }

  set numsta(value: string) {
    this._numsta = value;
  }

  get typsta(): string {
    return this._typsta;
  }

  set typsta(value: string) {
    this._typsta = value;
  }

  get adrsta(): string {
    return this._adrsta;
  }

  set adrsta(value: string) {
    this._adrsta = value;
  }

  get possta(): string {
    return this._possta;
  }

  set possta(value: string) {
    this._possta = value;
  }

  get locsta(): string {
    return this._locsta;
  }

  set locsta(value: string) {
    this._locsta = value;
  }

  get provin(): string {
    return this._provin;
  }

  set provin(value: string) {
    this._provin = value;
  }

  get basver(): string {
    return this._basver;
  }

  set basver(value: string) {
    this._basver = value;
  }

  get rivier(): string {
    return this._rivier;
  }

  set rivier(value: string) {
    this._rivier = value;
  }

  get pfxsta(): string {
    return this._pfxsta;
  }

  set pfxsta(value: string) {
    this._pfxsta = value;
  }

  get telsta(): string {
    return this._telsta;
  }

  set telsta(value: string) {
    this._telsta = value;
  }

  get staope(): string {
    return this._staope;
  }

  set staope(value: string) {
    this._staope = value;
  }

  get loglab(): number {
    return this._loglab;
  }

  set loglab(value: number) {
    this._loglab = value;
  }

  get latlab(): number {
    return this._latlab;
  }

  set latlab(value: number) {
    this._latlab = value;
  }

  get misser(): Date {
    return this._misser;
  }

  set misser(value: Date) {
    this._misser = value;
  }

  get carign(): string {
    return this._carign;
  }

  set carign(value: string) {
    this._carign = value;
  }

  get alarms(): string {
    return this._alarms;
  }

  set alarms(value: string) {
    this._alarms = value;
  }

  get alarma(): string {
    return this._alarma;
  }

  set alarma(value: string) {
    this._alarma = value;
  }

  get alarmm(): string {
    return this._alarmm;
  }

  set alarmm(value: string) {
    this._alarmm = value;
  }

  get coddeb(): string {
    return this._coddeb;
  }

  set coddeb(value: string) {
    this._coddeb = value;
  }

  get commen(): string {
    return this._commen;
  }

  set commen(value: string) {
    this._commen = value;
  }

  get cartrw(): string {
    return this._cartrw;
  }

  set cartrw(value: string) {
    this._cartrw = value;
  }

  get phosta(): string {
    return this._phosta;
  }

  set phosta(value: string) {
    this._phosta = value;
  }

  get imaign(): string {
    return this._imaign;
  }

  set imaign(value: string) {
    this._imaign = value;
  }

  get vueaer(): string {
    return this._vueaer;
  }

  set vueaer(value: string) {
    this._vueaer = value;
  }

  get datcre(): Date {
    return this._datcre;
  }

  set datcre(value: Date) {
    this._datcre = value;
  }

  get datmod(): Date {
    return this._datmod;
  }

  set datmod(value: Date) {
    this._datmod = value;
  }

  get codusr(): string {
    return this._codusr;
  }

  set codusr(value: string) {
    this._codusr = value;
  }

  get te2sta(): string {
    return this._te2sta;
  }

  set te2sta(value: string) {
    this._te2sta = value;
  }

  get pubint(): string {
    return this._pubint;
  }

  set pubint(value: string) {
    this._pubint = value;
  }

  get basoff(): string {
    return this._basoff;
  }

  set basoff(value: string) {
    this._basoff = value;
  }

  get tcodes(): Tcode[] {
    return this._tcodes;
  }

  set tcodes(value: Tcode[]) {
    this._tcodes = value;
  }

  get codins(): string {
    return this._codins;
  }

  set codins(value: string) {
    this._codins = value;
  }

  get datpub(): Date {
    return this._datpub;
  }

  set datpub(value: Date) {
    this._datpub = value;
  }

  get seqrap(): number {
    return this._seqrap;
  }

  set seqrap(value: number) {
    this._seqrap = value;
  }

  get nomsta_min(): string {
    return this._nomsta_min;
  }

  set nomsta_min(value: string) {
    this._nomsta_min = value;
  }

  get newzs(): Newz[] {
    return this._newzs;
  }

  set newzs(value: Newz[]) {
    this._newzs = value;
  }

  get tcode():Tcode{
    return this._tcodes.find(t => t.czref=="BASOFF");
  }

  get parametres(): Parametre[] {
    return this._parametres;
  }

  set parametres(value: Parametre[]) {
    this._parametres = value;
  }
}
