import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {MesureData} from '@app/models/mesure-data.model';
import {TableExport} from 'tableexport';
import {DateUtils} from '@app/shared/utils/date.utils';
import {ParametreOption} from '@app/models/parametreOption';

@Component({
  selector: 'app-mesure-data',
  templateUrl: './mesure-data.component.html',
  styleUrls: ['./mesure-data.component.scss']
})
export class MesureDataComponent implements OnInit {

  @Input() code: string;
  @Input() datas: MesureData[];
  @Input() station: string;
  @Input() param: ParametreOption;
  @Input() dates: Date;
  @Input() datee: Date;

  @ViewChild('tableExport') tableExport:ElementRef ;
  @ViewChild('tableExport15m') tableExport15m:ElementRef ;

  paramUnit: string = null;

  table: TableExport = null;

  cols2h = [
    { field: 'dateExp', header: 'Date', type: 'text' },
    { field: 'codper', header: 'Heure', type: 'text' },
    { field: 'param', header: 'Paramètre', type: 'text' },
    { field: 'mesure', header: 'Mesure', type: 'data' },
    { field: 'type', header: 'Type', type: 'text' },
    { field: 'detection', header: 'Détection', type: 'text' }
  ];

  cols15m = [
    { field: 'dateExp', header: 'Date', type: 'text' },
    { field: 'codper', header: 'Période', type: 'text' },
    { field: 'param', header: 'Paramètre', type: 'text' },
    { field: 'mesure', header: 'Mesure', type: 'data' }
  ];

  showData(data:MesureData):boolean{
    //table
    if(data.type == null){
      if(data.detection == null || data.detection == 'MINDIS'){
        return false;
      }
    }else if(data.type == 'MINDIS'){
      return false;
    }
    return true;
  }

  isData(type):boolean {
    return type === 'data';
  }

  constructor() { }

  ngOnInit() {
    this.paramUnit = this.datas[0].parametre.coduni.coduni;
  }

  exportAsXLSX(): void {
    this.cleanExport();
    var tableExport;
    var filename = this.station+'-'+this.param.code;
    filename += '-'+DateUtils.formatDate(this.dates, DateUtils.FORMAT.BE);
    filename += '-'+DateUtils.formatDate(this.datee, DateUtils.FORMAT.BE);
    if(this.tableExport){
      tableExport = this.tableExport;
      filename += '-2h';
    }else{
      tableExport = this.tableExport15m;
      filename += '-15m';
    }

    const options = {
      formats: ['xlsx'],
      position: 'top',
      filename: filename
    };

    this.table = new TableExport(tableExport['containerViewChild'].nativeElement.getElementsByTagName("table"), options);

    // @ts-ignore
    this.table.typeConfig.date.assert = function(v) {return false;};

    tableExport['containerViewChild'].nativeElement.getElementsByTagName("button")[0].click();
    this.cleanExport();
  }

  cleanExport(){
    if(this.table){
      this.table.remove();
    }
  }

}
