import {DateUtils} from '@app/shared/utils/date.utils';

export class Newz {

  public idnews: number;
  public nomsta: string;
  public codpar: string;
  public datnew: Date;
  public titnew: string;
  public txtnew: string;
  public datexp: Date;
  public codusr: string;

  public parametre: string;

  constructor(obj: any) {
    Object.assign(this, obj);
    this.datnew = obj.datnew ? DateUtils.newDate(obj.datnew) : null;
    this.datexp = obj.datexp ? DateUtils.newDate(obj.datexp) : null;
  }

}

